import { CSSProperties } from 'react';
import styles from './DropDown.module.css'

interface IDropDownProps {
    name: string | undefined,
    id?: string | undefined,
    onChangeHandler: (val: any) => void,
    value: string | undefined,
    style: CSSProperties | undefined,
    items: string[],
    placeHolder: string,
    defaultValue: string,
    divStyle: CSSProperties | undefined,
    disabled: boolean,
}

const DropDown = ({ name, onChangeHandler, value, style, id, items, placeHolder, defaultValue, divStyle, disabled }: IDropDownProps) => (

    <div style={divStyle ? divStyle : {}}>
        <select className={styles.DropDown} name={name} id={id} onChange={onChangeHandler} value={value} style={style} disabled={disabled}>
            {placeHolder && <option value="undefined" key="default" disabled >{placeHolder}</option>}
            {items && items.map(i => {
                return <option key={i} value={i}>{i}</option>
            })}
        </select>
    </div>
)

export default DropDown;