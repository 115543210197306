import { CSSProperties } from 'react';
import styles from './Button.module.css'


interface IButtonProps {
    name: string | undefined,
    onClickHandler?: (event: React.MouseEvent<HTMLButtonElement>) => void,
    btnname: string,
    iconClass?: string,
    value?: string,
    loaderStyles?: CSSProperties | undefined,
    iconStyle?: CSSProperties | undefined,
    style?: CSSProperties | undefined,
    secondary?: boolean,
    smallButton?: boolean,
    imgSrc?: string,
    onMouseEnter?: React.MouseEventHandler<HTMLButtonElement> | undefined,
    onMouseLeave?: React.MouseEventHandler<HTMLButtonElement> | undefined,
    loader?: boolean,
    disabled?: boolean,
    id?: string
}

const Button = ({ name, id, onClickHandler, btnname, secondary, smallButton, iconClass, loaderStyles, iconStyle, style, imgSrc, onMouseEnter, onMouseLeave, disabled = false, loader }: IButtonProps) => {
    let buttonClasses = [styles.Button];
    if (secondary) {
        buttonClasses.push(styles.Secondary)
    }
    if (smallButton) {
        buttonClasses.push(styles.Small)
    }
    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                {loader && <div className={styles.Buttonloader} style={loaderStyles}></div>}
                <div> <button id={id} name={btnname} disabled={disabled} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={buttonClasses.join(' ')} style={style} onClick={onClickHandler}>
                    {name}
                    {iconClass && <i id={btnname} className={iconClass} style={iconStyle}></i>}
                    {imgSrc && <img src={process.env.PUBLIC_URL + imgSrc} alt="" />}
                </button></div>
            </div>
        </>
    )
}

export default Button;