import { CSSProperties } from 'react';
import styles from './TextArea.module.css'

interface ITextareaProps {
    name: string | undefined,
    onChangeHandler?: (event: any) => void,
    placeholder?: string,
    style?: CSSProperties | undefined,
    id?: string,
    iconClassName?: string,
    value: string,
    iconOnClick?: undefined | (() => void),
    iconId?: string,
    icon2ClassName?: string,
    icon2OnClick?: undefined | (() => void),
    icon2Id?: string,
    icon2Style?: CSSProperties | undefined,
    disabled?: boolean,
    containerStyle?: CSSProperties | undefined,
    iconStyle?: CSSProperties | undefined,
    rows?: number | undefined,
    cols?: number | undefined

}

const TextArea = ({ onChangeHandler, rows, cols, value, placeholder, style, name, id, iconClassName, iconOnClick, iconId, iconStyle, icon2ClassName, icon2OnClick, icon2Id, icon2Style, disabled = false, containerStyle }: ITextareaProps) => (

    <div className={styles.TextBoxContainer} style={containerStyle} key={id}>
        <textarea rows={rows} cols={cols} disabled={disabled} name={name ? name : "input-form"} id={id} className={styles.TextBox} style={style} placeholder={placeholder} value={value} onChange={onChangeHandler} />
        {iconClassName && <i className={iconClassName} id={iconId} style={iconStyle} onClick={iconOnClick}></i>}
        {icon2ClassName && <i className={icon2ClassName} id={icon2Id} style={icon2Style} onClick={icon2OnClick}></i>}
    </div>
)

export default TextArea;