import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import style from "./App.module.css";
import PlayGround from './Components/PlayGround/PlayGround';
import { ToastContainer } from 'react-toastify'


function App() {
  return (
    <>

      <div className={style.Container}>
        {/* <Routes>
          <Route path='/' element={<PlayGround />} />
        </Routes> */}
        <PlayGround />
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
