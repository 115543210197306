import { CSSProperties } from 'react';
import styles from './TextBox.module.css'

interface ITextboxProps {
    name?: string | undefined,
    onChangeHandler?: (val: any) => void,
    onfocusHandler?: (val: any) => void,
    placeholder?: string,
    type?: string,
    style?: CSSProperties | undefined,
    id?: string,
    iconClassName?: string,
    value?: string | undefined,
    iconOnClick?: undefined | (() => void),
    iconId?: string,
    icon2ClassName?: string,
    icon2OnClick?: undefined | (() => void),
    icon2Id?: string,
    icon2Style?: CSSProperties | undefined,
    disabled: boolean,
    containerStyle?: CSSProperties | undefined,
    iconStyle?: CSSProperties | undefined

}

const TextBox = ({ onChangeHandler, onfocusHandler, value, placeholder, type, style, name, id, iconClassName, iconOnClick, iconId, iconStyle, icon2ClassName, icon2OnClick, icon2Id, icon2Style, disabled = false, containerStyle }: ITextboxProps) => (

    <div className={styles.TextBoxContainer} style={containerStyle} key={id}>
        <input disabled={disabled} type={type} name={name ? name : "input-form"} id={id} className={styles.TextBox} style={style} placeholder={placeholder} value={value} onChange={onChangeHandler} onBlur={onfocusHandler} />
        {iconClassName && <i className={iconClassName} id={iconId} style={iconStyle} onClick={iconOnClick}></i>}
        {icon2ClassName && <i className={icon2ClassName} id={icon2Id} style={icon2Style} onClick={icon2OnClick}></i>}
    </div>
)

export default TextBox;