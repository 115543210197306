import { toast } from 'react-toastify';
export const Toaster = (type: string, message: string, duration = 3000) => {
    if (type === "success") {
        toast.success(message, {
            position: "top-right",
            autoClose: duration,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else if (type === "warning") {
        toast.warning(message, {
            position: "top-right",
            autoClose: duration,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } else {
        toast.error(message, {
            position: "top-right",
            autoClose: duration,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}