import TextArea from "../Common/TextArea/TextArea";
import style from "./PlayGround.module.css";
import { useEffect, useState } from "react";
import DropDown from "../Common/DropDown/Dropdown";
import Button from "../Common/Button/Button";
// import axios from "../Common/axios";
import axios from "axios";
import TextBox from "../Common/TextBox/TextBox";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toaster } from "../Common/Toaster";
import { useSelector } from "react-redux";
import { login, logout } from "../../auth/keycloak";
import CheckBox from "../Common/CheckBox/CheckBox";



interface IPortal {
    environmentid: number,
    environment: string,
    Projects: IProjects[]
}

interface IProjects {
    id: number,
    name: string
}



const PlayGround: React.FC<{}> = props => {

    const [text, setText] = useState<string>('');
    const [input, setInput] = useState<string>('');
    const [data, setData] = useState<string>('');
    const [output, setOutput] = useState<string>('');
    const [selectedEnv, setSelectedEnv] = useState<string>();
    const [selectedEnvId, setSelectedEnvId] = useState<number>();
    const [environments, setEnvironments] = useState<string[]>([]);
    const [selectedProject, setSelectedProject] = useState<string>();
    const [selectedProjectId, setSelectedProjectId] = useState<number>();
    const [projects, setProjects] = useState<string[]>([]);
    const [selectedPolicy, setSelectedPolicy] = useState<string>();
    const [policy, setPolicy] = useState<string[]>([]);
    const [result, setResult] = useState<IPortal[]>([])
    const [policyData, setPolicyData] = useState<any>([])
    const [newPolicy, setNewPolicy] = useState<string>();
    const [isAdd, setIsAdd] = useState<boolean>(false);
    const [isDisable, setIsDisable] = useState<boolean>(true);
    const [packageName, setPackageName] = useState<string>();
    const [isSaved, setIsSaved] = useState<boolean>(false)
    const [tokenDecode, setTokenDecode] = useState<any>({})
    const [isDecode, setIsDecode] = useState<boolean>(false)

    const auth = useSelector((state: any) => state.auth);

    const onChangeValue = (event: any) => {
        if (event.target.name === "text") {
            let data = event.target.value;
            let packageData = `package ${selectedEnv}.${selectedProject}.${selectedPolicy}\n`;
            if (!data.trim().includes(packageData.trim())) {
                setText(text)
            } else {
                let str = data.replace(packageData.trim(), "");
                setText(packageData.trim() + str)
            }
        }
        else if (event.target.name === "input") setInput(event.target.value)
        else if (event.target.name === "data") setData(event.target.value)
        else if (event.target.name === "output") setOutput(event.target.value)
        else if (event.target.name === "policy-name") setNewPolicy(event.target.value)

    }
    let divStyle = { minHeight: "15rem", fontSize: "13px", background: "url(http://i.imgur.com/2cOaJ.png)", backgroundAttachment: "local", backgroundRepeat: "no-repeat", paddingLeft: "35px", paddingTop: "10px", borderColor: "#ccc" };
    useEffect(() => {
        const headers = {
            'Content-Type': 'application/json'
        };

        axios.get(`https://devpolicy-api.cloud.korewireless.com/environment`, { headers })
            .then(({ data }) => {

                setResult(data);
                let envList: string[] = []
                data && data.map((p: any) => {

                    envList.push(p.environment)
                })
                setEnvironments(envList)

            }).catch((err) => {
                console.log(err);

            })

    }, [])


    const selectEnvtHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDisable(true)
        setSelectedProject('')
        setSelectedPolicy('')
        setText('')
        setData('')
        setInput('')
        setPackageName('')
        setIsSaved(true)
        setSelectedEnv(event.target.value)
        let selectedEnvData = result && result.find((p: any) => {
            if ((p.environment == selectedEnv)) {
                return p.environmentid
            }
        });
        setSelectedEnvId(selectedEnvData?.environmentid)

        let projectsList: string[] = []
        if (result != undefined) {
            result && result.map((i) => {
                if (i.environment === event.target.value) {
                    i.Projects.map((project) => {
                        projectsList.push(project.name)
                    })
                    setProjects(projectsList)
                }
            })
        }


    }

    const selectProjectHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDisable(true)
        setSelectedPolicy('');
        setText('')
        setData('')
        setInput('')
        setPackageName('')
        setIsAdd(false)
        setSelectedProject(event.target.value)
        let selectedEnvData = result && result.find((p: any) => (p.environment == selectedEnv));
        setSelectedEnvId(selectedEnvData?.environmentid)



        selectedEnvData && selectedEnvData.Projects.map((project) => {

            if (project.name === event.target.value && selectedEnvData?.environment === selectedEnv) {
                setSelectedProjectId(project.id)
                axios.get(`https://devpolicy-api.cloud.korewireless.com/policy`)
                    .then(({ data }) => {

                        setPolicyData(data)
                        let policies: string[] = []
                        data && data.map((i: any) => {
                            if (i.projectid === project.id && i.enironmentid === selectedEnvData?.environmentid) {
                                policies.push(i.policyname)
                            }
                        })
                        setPolicy(policies)


                    }).catch((err) => {
                        console.log(err);

                    })
            }
        })



    }

    const selectPolicyHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDisable(true)
        setSelectedPolicy(event.target.value)
        setPackageName([selectedEnv!, selectedProject!].join('.'))


        let resultPolicy = policyData && policyData.find((p: any) => (p.enironmentid === selectedEnvId && p.projectid === selectedProjectId));

        setData(resultPolicy?.policydata)
        let packageData = `package ${selectedEnv}.${selectedProject}.${event.target.value}\n`;
        if (!resultPolicy?.policy.trim().includes(packageData.trim())) {
            setText(resultPolicy?.policy)
        } else {
            let str = resultPolicy?.policy.replace(packageData.trim(), "");
            setText(packageData.trim() + str)
        }
        // setText(resultPolicy?.policy)
        setInput("")




    }

    const onAddPolicy = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsAdd(true)
        setIsDisable(false)
        setNewPolicy('')
        setText(selectedEnv && selectedProject && selectedPolicy ? `package ${selectedEnv}.${selectedProject}.${selectedPolicy}\n` : "")
        setData('');
        setInput('')
        setIsSaved(false)

    }



    const onEditPolicy = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsAdd(false)
        setIsDisable(false)
        setIsSaved(false)

    }

    function parseJwt(token: any) {
        try {
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(jsonPayload);
        }
        catch (err) {

        }

    }

    const onClickSave = () => {

        let result = {
            enironmentid: selectedEnvId,
            projectid: selectedProjectId,
            policyname: newPolicy !== undefined ? newPolicy : selectedPolicy,
            policy: text,
            policydata: data !== undefined ? data : ""

        }


        if (isAdd) {
            axios.post(`https://devpolicy-api.cloud.korewireless.com/policy`, JSON.stringify(result))
                .then(({ data }) => {

                    Toaster("success", "Policy Details added successfully");
                    setIsDisable(true)
                    setIsSaved(true)
                    // setText('')
                    // setInput('')
                    // setData('')
                    // setSelectedEnv('')
                    // setSelectedPolicy('');
                    // setSelectedProject('')

                })
                .catch(function (error) {
                    setIsDisable(false)
                    setIsSaved(false)
                    if (error.response) {
                        Toaster("Error", error.response.data.message)


                    }
                })
        }
        else {

            axios.patch(`https://devpolicy-api.cloud.korewireless.com/policy`, JSON.stringify(result))
                .then(({ data }) => {

                    Toaster("success", "Policy Details has been updated successfully");
                    setIsDisable(true)
                    setIsSaved(true)
                    // setText('')
                    // setInput('')
                    // setData('')
                    // setSelectedEnv('')
                    // setSelectedPolicy('');
                    // setSelectedProject('')

                })
                .catch(function (error) {
                    setIsDisable(false)
                    setIsSaved(false)
                    if (error.response) {
                        Toaster("Error", error.response.data.message)
                        return;
                    }
                })
        }



        setIsAdd(false)

    }

    const onClear = () => {
        setText('')
        setInput('')
        setData('')
        setSelectedEnv('')
        setSelectedPolicy('');
        setSelectedProject('')

    }

    const signinHandler = (e: any) => {
        e.stopPropagation();
        if (auth?.isAuthorized) {
            logout();
        } else {
            login()
        }
    }

    const onCreatebucket = () => {
        var params = {
            environmentid: selectedEnvId
        }

        axios.post(`https://devpolicy-api.cloud.korewireless.com/bundle`, JSON.stringify(params))
            .then(({ data }) => {
                Toaster("success", "Policies are published successfully.");

            }).catch(function (error) {
                if (error.response) {

                    Toaster("Error", error.response.data.message)
                    return;
                }
            })

    }

    const onDecode = (event: any) => {
        setIsDecode(event.target.checked)
        if (event.target.checked) {
            var tokenDecoded = parseJwt((localStorage.getItem('accessToken'))!)
            setTokenDecode(JSON.stringify(tokenDecoded, null, 2))

        }

    }

    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "10px 0px 0px 25px" }} >
                <div style={{ display: "flex" }}>
                    <img alt='Logo' title='Logo'
                        style={{
                            display: "inline-block",
                            height: "3rem",
                            marginRight: "10px"
                        }}
                        src={`${process.env.PUBLIC_URL}/image/logo.png`}
                    />
                    <img alt='Logo' title='Logo'
                        style={{
                            display: "inline-block",
                            height: "3rem",
                        }}
                        src={`${process.env.PUBLIC_URL}/image/policy.png`}
                    />
                    <div className={style.BannerHead}>{"Policy Management"}</div>
                </div>
                <div className={style.SignIn}>
                    <Button onClickHandler={signinHandler} style={{ minWidth: "3px", padding: "12px 15px 12px 15px", margin: "12px 30px 0px 10px", borderRadius: "20px" }} name={auth?.isAuthorized ? "Sign out" : "Sign in"} iconClass={''} value={''} iconStyle={{ margin: "5px" }} imgSrc={''} disabled={false} btnname={auth?.isAuthorized ? "Sign out" : "Sign in"} secondary={true} smallButton={false} />
                </div>
            </div>

            <div className={auth?.isAuthorized ? style.AdminContainer : style.disabled}>
                {/* <div className={style.TabContainer}> */}
                <div className={style.TabContent}>
                    <div className={style.HeaderDiv}>
                        <div className={style.Select}>
                            <label className={style.Label}>Environment:</label>
                            <DropDown name="Environment"
                                onChangeHandler={selectEnvtHandler}
                                placeHolder="Select Environment"
                                value={selectedEnv || "undefined"}
                                defaultValue="undefined"
                                items={environments}
                                style={{ margin: "10px", padding: "7px 11px", width: "15rem" }} disabled={false} divStyle={undefined} />

                        </div>
                        <div className={style.Select}>
                            <label className={style.Label}>Project:</label>
                            <DropDown name="Project"
                                onChangeHandler={selectProjectHandler}
                                placeHolder="Select Project"
                                value={selectedProject || "undefined"}
                                defaultValue="undefined"
                                items={projects}
                                style={{ margin: "10px", padding: "7px 11px", width: "15rem" }} disabled={false} divStyle={undefined} />

                        </div>

                        <div className={style.Select}>
                            <label className={style.Label}>Policy:</label>
                            <DropDown name="Policy"
                                onChangeHandler={selectPolicyHandler}
                                placeHolder="Select Policy"
                                value={selectedPolicy || "undefined"}
                                defaultValue="undefined"
                                items={policy}
                                style={{ margin: "10px", padding: "7px 11px", width: "15rem" }} disabled={false} divStyle={undefined} />

                        </div>


                        <div className={style.Select}>
                            <Button onClickHandler={onAddPolicy} style={{ minWidth: "3px", padding: "5px 5px 5px 5px", margin: "12px 0px 0px 0px", borderRadius: "5px" }} name="Add" iconClass={''} value={''} iconStyle={{ margin: "5px" }} imgSrc={''} disabled={false} btnname={'Add Policy'} secondary={false} smallButton={true} />
                        </div>
                        {!isAdd && selectedPolicy && <div className={style.Select}>
                            <Button onClickHandler={onEditPolicy} style={{ minWidth: "3px", padding: "5px 5px 5px 5px", margin: "12px 0px 0px 0px", borderRadius: "5px" }} name="Edit" iconClass={''} value={''} iconStyle={{ margin: "5px" }} imgSrc={''} disabled={false} btnname={'Add Policy'} secondary={false} smallButton={true} />
                        </div>}
                        <div>
                            {isAdd &&
                                <TextBox
                                    onChangeHandler={onChangeValue}
                                    value={newPolicy || undefined}
                                    placeholder=""
                                    type="name"
                                    name="policy-name"
                                    disabled={false} style={{ marginTop: "10px", padding: "7px" }} />
                            }
                        </div>

                    </div>
                    <div style={{ display: "flex" }}>
                        <div className={style.TextAreaContainer}>
                            <div>
                                <div style={{ background: "#999999", marginTop: "15px", borderRadius: "2px" }}>
                                    <label style={{ fontSize: "15px", fontWeight: "bold", marginLeft: "10px" }}>POLICY </label>
                                </div>
                                <div className={style.textareaWrapper}>
                                    {/* <TextArea name="package" value={`package ${selectedEnv}.${selectedProject}.${selectedPolicy}\n` }/> */}
                                    <TextArea name={'text'} placeholder={""} rows={30} cols={100} style={divStyle} value={text} disabled={isDisable} onChangeHandler={onChangeValue} />
                                </div>
                            </div>
                        </div>
                        <div style={{ marginLeft: "5px" }}>
                            <div style={{ background: "#999999", marginTop: "15px", borderRadius: "2px" }}>
                                <label style={{ fontSize: "15px", fontWeight: "bold", marginLeft: "10px" }}>DATA</label>
                            </div>
                            <div>
                                <TextArea name={'data'} placeholder={""} rows={30} cols={100} style={divStyle} value={data} disabled={isDisable} onChangeHandler={onChangeValue} />
                            </div>

                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClickHandler={onClickSave} smallButton={true} name={"Save"} btnname={"Save"} style={{ margin: "0.5rem", minWidth: "90px" }} />
                        <Button onClickHandler={onCreatebucket} disabled={!isSaved} smallButton={true} name={"Publish"} btnname={"Publish"} style={{ margin: "0.5rem", minWidth: "90px" }} />
                        <Button onClickHandler={onClear} smallButton={true} name={"Clear"} btnname={"Clear"} style={{ margin: "0.5rem", minWidth: "90px" }} />
                    </div>
                </div>

            </div>
        </div>
    )

}


export default PlayGround


