import Keycloak, { KeycloakConfig } from 'keycloak-js';
import axios from '../Components/Common/axios';
import { store } from "../index";
import * as actionTypes from "../store/actions/actionTypes";


export const keycloak: any = Keycloak(`./resources/keycloak-Dev.json`);

export default class KeycloakManager {
    constructor() {
        store.dispatch({
            type: actionTypes.SET_IS_AUTHCHECKING,
            isAuthChecking: true
        })
        try {
            keycloak.init({
                onLoad: "check-sso",
                // checkLoginIframe: true,
                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
            })
                .then((authenticated: boolean) => {
                    // test()
                    if (authenticated) {

                        storeTokens();
                        store.dispatch({
                            type: actionTypes.SET_IS_AUTHORIZE,
                            isAuthorized: true
                        })
                        store.dispatch({
                            type: actionTypes.SET_USERDATA,
                            userName: keycloak?.tokenParsed?.given_name,
                            isAdmin: keycloak?.tokenParsed?.isAdmin
                        })
                    } else {
                        keycloak.login();
                        console.log("user is not authenticated ")
                        store.dispatch({
                            type: actionTypes.SET_IS_AUTHORIZE,
                            isAuthorized: false
                        })
                    }
                    store.dispatch({
                        type: actionTypes.SET_IS_AUTHCHECKING,
                        isAuthChecking: false
                    })
                })
                .catch((err: Error) => {
                    console.error("Error in getting token ", err)
                    store.dispatch({
                        type: actionTypes.SET_IS_AUTHORIZE,
                        isAuthorized: false
                    })
                    store.dispatch({
                        type: actionTypes.SET_IS_AUTHCHECKING,
                        isAuthChecking: false
                    })
                })
        } catch (e) {
            console.error("Error in keycloak init ", e)
            store.dispatch({
                type: actionTypes.SET_IS_AUTHCHECKING,
                isAuthChecking: false
            })
        }
    }
}

const storeTokens = () => {    
    localStorage.setItem("accessToken", keycloak.token)
    localStorage.setItem("idToken", keycloak.idToken)

    axios.defaults.headers.common['Authorization'] = keycloak.token || "";
}

export const logout = () => {
    localStorage.clear()
    keycloak.logout()
}

export const login = () => {
    keycloak.login()
}

keycloak.onTokenExpired = () => {
    keycloak.updateToken(2).then((refreshed: boolean) => {
        if (refreshed) {
            storeTokens();
        } else {
            //console.log('not refreshed ', keycloak.token);
        }
    }).catch((e: Error) => {
        localStorage.clear();
        store.dispatch({
            type: actionTypes.SET_IS_AUTHORIZE,
            isAuthorized: false
        })
        console.error('Failed to refresh token ', e);
    });
}